import {Close} from "@mui/icons-material";
import {Paper} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import React from "react";

import {NOTIFICATION_EVENTS, SEVERITY} from "../../../../config/event_config";
import iconMapping from "./icon_mapping_notification";
import useStyles from "./notification.styles";

/**
 * Render Notification component
 * @param {object} props
 * @param {Boolean} props.open
 * @param {Function} props.onClose
 * @param {String} props.titleText
 * @param {String} props.event one of the NOTIFICATION_EVENTS
 * @param {Number} props.width
 * @param {String} props.timestamp
 * @param {JSX.Element[]} children
 * @return {React.ReactElement}
 */
export const Notification = ({open, onClose, titleText, event, width, timestamp, children}) => {
    const {classes, cx} = useStyles();
    const IconName = iconMapping[NOTIFICATION_EVENTS[event]?.severity] || iconMapping.Default;

    return (
        <Paper
            className={cx(classes.root, {
                [classes.info]: NOTIFICATION_EVENTS[event]?.severity === SEVERITY.INFO,
                [classes.error]: NOTIFICATION_EVENTS[event]?.severity === SEVERITY.ERROR, // there is no severity error, should it be set from the category?
                [classes.success]: NOTIFICATION_EVENTS[event]?.severity === SEVERITY.SUCCESS
            })}
            elevation={2}
            style={{display: open ? "block" : "none", width: width + "rem"}}
        >
            <div className={classes.titleArea}>
                <span className={classes.left}>
                    <IconName className={classes.icon} />
                    <div className={classes.text} title={titleText}>
                        {titleText}
                    </div>
                </span>
                <span className={classes.right}>
                    <span>{timestamp}</span>
                    <IconButton size="large" onClick={onClose}>
                        <Close />
                    </IconButton>
                </span>
            </div>
            <div className={classes.content}>{children}</div>
        </Paper>
    );
};

Notification.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    width: PropTypes.number,
    titleText: PropTypes.string.isRequired,
    event: PropTypes.string,
    timestamp: PropTypes.string,
    children: PropTypes.node.isRequired
};

Notification.defaultProps = {
    width: 30 // in rem
};

export default Notification;
