// @ts-check
import {LoadingButton} from "@mui/lab";
import {Tooltip} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {publishAction, resetPublishStatusAction} from "../../../pages/op_backlog/op_backlog_actions";
import {selectOpBacklog} from "../../../pages/op_backlog/op_backlog_selectors";
import {loadStateAction} from "../../../pages/op_management/op_management_actions";
import {selectSessionEditedBy, selectUsernames} from "../../../pages/op_management/op_management_selectors";
import {selectCurrentUserEmail} from "../../../redux/app_selectors";
import {isPending, isRejected, isResolved} from "../../../redux/utils/status";
import formatUsername from "../../../utils/format_username";
import Message from "../../shared/message/message";
import useStyles from "./publish.styles";

/**
 * render Publish component
 * @return {React.ReactElement}
 */
const Publish = () => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const {publishStatus} = useSelector(selectOpBacklog);
    const editedBy = useSelector(selectSessionEditedBy);
    const userEmail = useSelector(selectCurrentUserEmail);
    const usernames = useSelector(selectUsernames);

    useEffect(() => {
        if (isResolved(publishStatus)) {
            setMessage(t("OpManagement.successPublishPlan"));
            setTimeout(() => setMessage(null), 5 * 1000); // @todo #14763 to be improved

            // reset status
            dispatch(resetPublishStatusAction());

            // reload state
            dispatch(loadStateAction());
        }
        if (isRejected(publishStatus)) {
            setError(t("OpManagement.errorPublishPlan"));
            setTimeout(() => setError(null), 5 * 1000); // @todo #14763 to be improved

            // reset status
            dispatch(resetPublishStatusAction());
        }
    }, [publishStatus]);

    const handlePublish = () => {
        dispatch(publishAction());
    };

    // Build initials of editing user
    const editUserInitials =
        usernames && usernames[editedBy] ? usernames[editedBy].firstName.charAt(0).concat(usernames[editedBy].lastName.charAt(0)) : null;

    const isSomeoneEditing = Boolean(editedBy && editedBy !== userEmail);
    return (
        <div className={classes.publishRoot}>
            {message && <Message message={message} />}
            {error && <Message message={error} severity="error" />}
            {isSomeoneEditing && (
                <div className={classes.editByWrapper}>
                    <span className={classes.editByLabel}>{t("Publish.editing")}</span>
                    <Tooltip title={formatUsername(editedBy, usernames)}>
                        <span className={classes.editUserIcon}>{editUserInitials}</span>
                    </Tooltip>
                </div>
            )}
            <LoadingButton
                className={classes.button}
                color="primary"
                disabled={isSomeoneEditing}
                loading={isPending(publishStatus)}
                variant="contained"
                onClick={handlePublish}
            >
                {t("OpBacklogView.publish")}
            </LoadingButton>
        </div>
    );
};
export default Publish;
