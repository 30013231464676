import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        height: "auto",
        maxHeight: "12rem",
        opacity: 1,
        transition: "max-height 1s ease, opacity .5s ease"
    },
    collapsed: {
        maxHeight: 0,
        opacity: 0
    },
    details: {
        marginBottom: theme.spacing("m")
    }
}));
