import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    infoIcon: {
        color: theme.palette.info.main,
        fontSize: "1rem"
    },
    infoTicker: {
        height: "30px",
        width: "100%",
        color: theme.palette.common.black,
        background: "#f7f7f7",
        display: "flex",
        alignItems: "center",
        padding: "0 1rem"
    },
    infoTickerItemWrapper: {
        position: "relative",
        display: "inline-block",
        width: "calc(100% - 2rem)",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    infoTickerItem: {
        "flexShrink": 0,
        "paddingLeft": "0.5rem",
        "whiteSpace": "nowrap",
        "display": "inline",
        "& *": {
            display: "inline"
        },
        "&:after": {
            display: "inline",
            content: '"//"'
        },
        "&:last-of-type": {
            "&:after": {
                display: "inline",
                content: '""'
            }
        }
    },
    name: {
        fontWeight: theme.typography.fontWeightBold,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    tooltip: {
        maxWidth: "600px"
    },
    tooltipContent: {
        display: "grid"
    }
}));
