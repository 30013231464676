// @ts-check
import {MoreVert} from "@mui/icons-material";
import {IconButton, Menu, MenuItem} from "@mui/material";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import DataTable from "../../shared/data_table/data_table";
import useStyles from "./user_notifications_table.styles";

const ALL = "all";

/**
 * render UserNotificationsTable component
 * @param {Object} props
 * @param {Array<NotificationType>} props.notifications
 * @param {Function} props.onNotificationDelete
 * @param {Function} props.onNotificationConfirm
 * @param {Function} props.onNotificationUnread
 * @param {Number} props.tableTopBottomPadding
 * @param {Number} props.page
 * @param {Function} props.onSetPage
 * @return {React.ReactElement}
 */
const UserNotificationsTable = ({
    notifications,
    onNotificationDelete,
    onNotificationConfirm,
    onNotificationUnread,
    tableTopBottomPadding,
    page = 0,
    onSetPage
}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const {formatFromISO} = useContext(DateContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const [notificationId, setNotificationId] = useState(null); // notificationId clicked options
    const [deleteId, setDeleteId] = useState(null);

    const handleClickOptions = (e, id) => {
        setAnchorEl(e.currentTarget);
        setNotificationId(id);
    };

    const handleCloseOptions = () => {
        setAnchorEl(null);
        setNotificationId(null);
    };

    const handleDelete = (id) => {
        setDeleteId(id);
        setTimeout(() => onNotificationDelete(id), 500);
    };

    const notificationsFormatted = notifications.map((row) => {
        const titleFormatted = t([row.title, "Notification.title_default"]);

        const hcServices = row?.references?.length ? row.references.filter((item) => item.type === "HealthcareService") : [];
        const hcServiceFormat =
            hcServices.length && hcServices[0].reference === ALL
                ? t("Notification.allDisciplines")
                : hcServices.map((el) => t(`HealthcareService.${el.reference}`)).join(", ");

        const messageFormatted = t([row.message, "Notification.text_default"], {discipline: hcServiceFormat});
        const date = `${formatFromISO(row.createdAt, DATE_FORMATS.DATE)} (${formatFromISO(row.createdAt, DATE_FORMATS.TIME)})`;
        return {
            _id: row._id,
            key: row._id,
            date: row.createdAt,
            title: titleFormatted,
            message: messageFormatted,
            options: "",
            isHidden: row._id === deleteId,
            display: {
                date: (
                    <span
                        className={cx({
                            [classes.isNew]: row.isNew
                        })}
                    >
                        {date}
                    </span>
                ),
                title: (
                    <span
                        className={cx({
                            [classes.isNew]: row.isNew
                        })}
                    >
                        {titleFormatted}{" "}
                    </span>
                ),
                message: (
                    <span
                        className={cx({
                            [classes.isNew]: row.isNew
                        })}
                    >
                        {messageFormatted}
                    </span>
                ),
                options: (
                    <>
                        <IconButton key={"icon-button"} size="small" onClick={(e) => handleClickOptions(e, row._id)}>
                            <MoreVert fontSize="small" />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                            classes={{paper: classes.menu}}
                            key={"menu"}
                            open={notificationId === row._id && Boolean(anchorEl)}
                            transformOrigin={{vertical: "top", horizontal: "right"}}
                            onClose={handleCloseOptions}
                        >
                            {row.isNew ? (
                                <MenuItem key={"confirm"} onClick={() => onNotificationConfirm(row._id)}>
                                    {t("Notification.confirm")}
                                </MenuItem>
                            ) : (
                                <MenuItem key={"unread"} onClick={() => onNotificationUnread(row._id)}>
                                    {t("Notification.unread")}
                                </MenuItem>
                            )}
                            <MenuItem key={"delete"} onClick={() => handleDelete(row._id)}>
                                {t("Notification.delete")}
                            </MenuItem>
                        </Menu>
                    </>
                )
            },
            tooltip: {
                date,
                title: titleFormatted,
                message: messageFormatted
            }
        };
    });
    const labels = [
        {id: "date", label: t("UserNotificationsTable.date"), width: "15%", setTitle: true},
        {id: "title", label: t("UserNotificationsTable.title"), width: "20%", setTitle: true},
        {id: "message", label: t("UserNotificationsTable.message"), width: "65%", setTitle: true},
        {id: "options", label: "", width: "50px"}
    ];
    return (
        <DataTable
            data={notificationsFormatted}
            defaultOrder="desc"
            defaultSorted="date"
            disableSortColumns={["options"]}
            labels={labels}
            page={page}
            tableLeftRightPadding={64 + 30 + 30} // left menubar + table padding 30 + 30
            tableTopBottomPadding={tableTopBottomPadding}
            onSetPage={onSetPage}
        />
    );
};

UserNotificationsTable.propTypes = {
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            event: PropTypes.string.isRequired,
            references: PropTypes.arrayOf(PropTypes.shape({})),
            severity: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired,
            isNew: PropTypes.bool
        })
    ),
    onNotificationDelete: PropTypes.func.isRequired,
    onNotificationConfirm: PropTypes.func.isRequired,
    onNotificationUnread: PropTypes.func.isRequired,
    tableTopBottomPadding: PropTypes.number,
    page: PropTypes.number,
    onSetPage: PropTypes.func.isRequired
};
UserNotificationsTable.defaultProps = {
    page: 0
};
export default UserNotificationsTable;
