// @ts-check
import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    requestRoot: {
        display: "flex",
        alignItems: "center"
    },
    infoTicker: {
        display: "flex",
        background: theme.palette.info.light,
        height: "30px",
        marginRight: "30px",
        padding: "0 0.5rem",
        alignItems: "center",
        border: `1px solid #DED7B1` // there is no color in the palette for this
    },
    infoIcon: {
        color: theme.palette.info.main,
        fontSize: "24px",
        paddingRight: "0.5rem"
    },
    infoText: {
        color: theme.palette.info.main,
        fontSize: "0.75rem",
        lineHeight: "normal"
    },
    button: {
        fontSize: "0.875rem"
    }
}));
