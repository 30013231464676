import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        position: "absolute",
        top: `-${theme.custom.headerHeight}`,
        right: "2rem",
        zIndex: theme.zIndex.drawer + 10
    },
    groupList: {
        listStyleType: "none",
        margin: 0,
        padding: 0,
        maxHeight: "100vh",
        overflowX: "visible",
        overflowY: "auto"
    },
    group: {
        listStyleType: "none",
        margin: 0,
        padding: 0,
        overflow: "hidden"
    },
    item: {
        "margin": "1rem 2rem 1rem 1.25rem",

        "&:first-of-type": {
            marginTop: 0
        }
    }
}));
