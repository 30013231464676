// @ts-check
import {CheckCircle, Create, Lock, ReportProblem} from "@mui/icons-material";
import {Box, ClickAwayListener, IconButton, Tooltip} from "@mui/material";
import PropTypes from "prop-types";
import React, {Fragment, memo, useCallback, useState} from "react";
import {useDrag} from "react-dnd";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../config/config.json";
import {completedStatus, inProgressStatus, preparationStatusOpManage} from "../../../config/op_status";
import {
    changeHoveredOp,
    saveDragStartPosition,
    setOverlapClickedOp,
    setOverlapHoveredOp
} from "../../pages/op_management/op_management_actions";
import {
    selectCriticalChangeOpIds,
    selectHighlightedOp,
    selectIsHovered,
    selectIsSelected,
    selectOverlapClickedOp,
    selectOverlapHoveredOp,
    selectPosition
} from "../../pages/op_management/op_management_selectors";
import {isEmergencyFunc} from "../../utils/is_emergency";
import {selectFeSettings} from "../fe_settings/fe_settings_selectors";
import {Cpb} from "../shared/cpb";
import OpOverlapIcon from "../shared/icons/op_overlap_icon";
import OpTimeLine from "../shared/op_time_line/op_time_line";
import Ward from "../shared/ward/ward";
import HeaderRow from "./components/header_row";
import OpTeam from "./components/op_team";
import useStyles from "./op_box_horizontal.styles";

const MIN_OP_DURATION = 15 * 60; // minimum size op in seconds

/**
 * @typedef {Object} OpBoxHorizontalShape
 * @param {number} props.topPosition in px
 * @param {number} props.startPercent
 * @param {number} props.endPercent
 * @param {number} props.barHeight in px
 */

/**
 * Render OpBoxHorizontal component
 *
 * @param {Object} props
 * @param {OpBoxHorizontalShape} props.shape
 * @param {OpBoxHorizontalContents} props.contents
 * @param {{isEditIconVisible: boolean, isEditable: boolean}} props.edit
 * @param {boolean} props.showDiscipline
 * @param {Function} props.onClick
 * @param {boolean} props.isDeemphasized
 * @param {Function} props.onClickEdit
 * @param {boolean} [props.isSearch]
 * @param {string} props.id
 * @param {{hexDiscipline: string}} props.styles
 * @param {string} props.status
 * @param {number} props.priority
 * @param {InternalTimestampsOpData} props.duration
 * @param {string} props.procedureCode
 * @param {string} props.hcServiceId
 * @param {boolean} props.hasOverlap
 * @param {number} props.adjustIndex
 * @return {JSX.Element}
 */
const OpBoxHorizontal = ({
    shape: {topPosition, startPercent, endPercent, barHeight},
    contents,
    edit,
    showDiscipline,
    onClick,
    isDeemphasized,
    onClickEdit,
    isSearch,
    id,
    styles,
    status,
    priority,
    duration,
    procedureCode,
    hcServiceId,
    hasOverlap,
    adjustIndex
}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const dispatch = useDispatch();

    const {
        TEXT_PUNCTUATION: {VERTICAL_SLASH}
    } = config;

    const {team, patient, intervention, duraCpb, medCleared, surgeonPresenting, startOpTime, isLocked} = contents;

    const isHovered = useSelector((state) => selectIsHovered(state, {opId: id}));
    const isSelected = useSelector((state) => selectIsSelected(state, {opId: id}));
    const criticalChangeOpIds = useSelector(selectCriticalChangeOpIds);
    const highlightedOp = useSelector(selectHighlightedOp);
    const position = useSelector(selectPosition);
    const {emergencyThreshold} = useSelector(selectFeSettings);
    const overlapHoveredOp = useSelector(selectOverlapHoveredOp);
    const overlapClickedOp = useSelector(selectOverlapClickedOp);

    // Use hovered to stretch short op
    const [hoveredState, setHoveredState] = useState(false);

    const handleDragStart = (monitor) => {
        const dragOffset = monitor.getClientOffset();
        const dragSourceOffset = monitor.getSourceClientOffset();
        const leftOffset = dragOffset.x - dragSourceOffset.x;
        dispatch(saveDragStartPosition({leftOffset}));
        return {opId: id, scrollLeft: position.scrollLeft, procedureCode, hcServiceId};
    };

    const handleDragEnd = () => {
        // Reset hoverState
        setHoveredState(false);
    };

    const [{isDragging}, drag, preview] = useDrag({
        type: isSearch ? "search" : "canvas",
        item: handleDragStart,
        end: handleDragEnd,
        collect: (monitor) => ({
            // currently not used
            isDragging: monitor.isDragging()
        })
    });

    const attach = useCallback(
        (domElement) => {
            drag(domElement);
            preview(opBox);
        },
        [hoveredState]
    );

    // Set discipline color on top right
    const disciplineColor = showDiscipline ? (
        <div className={classes.discipline} data-testid="discipline-color" style={{backgroundColor: styles.hexDiscipline}} />
    ) : null;

    /**
     * handler for opening detail layer
     * @param {React.KeyboardEvent<HTMLElement>|React.MouseEvent<HTMLElement>} e - event
     */
    const handleClick = (e) => {
        // if edit icon is clicked, then not to open detail layer
        if (onClick && e.target instanceof HTMLElement && !e.target.closest("path") && !e.target.closest("svg")) {
            const currentTarget = e.currentTarget;
            onClick({currentTarget, opId: id});
        }
    };

    /**
     * handler for mouse over, set hoveredOp in redux
     */
    const handleMouseOver = () => {
        setHoveredState(true);
        dispatch(changeHoveredOp(id));
    };

    /**
     * handler for mouse leave, reset hoveredOp in redux
     */
    const handleMouseLeave = () => {
        setHoveredState(false);
        dispatch(changeHoveredOp());
    };

    /**
     * handler for opening edit layer
     * @param {React.KeyboardEvent<HTMLElement>|React.MouseEvent<HTMLElement>} e
     */
    const handleClickEdit = (e) => {
        e.stopPropagation();
        onClickEdit && onClickEdit(id, procedureCode, hcServiceId);
    };

    const isEmergency = isEmergencyFunc(priority, emergencyThreshold);

    const content = (
        <Fragment>
            <HeaderRow
                id={patient?.id}
                isEditIconVisible={edit?.isEditIconVisible}
                observations={patient?.observations}
                startOpTime={startOpTime}
            />
            <div
                className={cx(classes.contentRow, {
                    [classes.contentRowBorder]: !isEmergency,
                    [classes.contentRowBorderEmergency]: isEmergency
                })}
            >
                {intervention}
                {` ${VERTICAL_SLASH}`}
                {duraCpb && (
                    <>
                        {` ${VERTICAL_SLASH} `}
                        <Cpb duraCpb={duraCpb} />
                    </>
                )}
                <Ward patientLocations={patient?.locations} />
            </div>
            <OpTeam isEmergency={isEmergency} surgeonPresenting={surgeonPresenting} team={team} />
        </Fragment>
    );
    const isClickedOrHovered = isSelected || isHovered;

    // Set gradient transparency if in search and longer than 75% of the parent element
    // Calculate percent of op part which should be shown in the layer (layer = 420 and op start/end = 15% and 180%, then )
    const percent = isSearch && endPercent > 75 && (75 / (endPercent - startPercent)) * 100;
    const gradientCss = percent && `linear-gradient(90deg, rgba(255,255,255,1) ${percent * 0.7}%, rgba(255,255,255,0) ${percent}%)`;

    const isShortOp = duration.duraRoomLockPost.refEnd < MIN_OP_DURATION;
    const showContent = !isShortOp || (isShortOp && hoveredState);

    const isSolvedEvent = criticalChangeOpIds.includes(id);
    const criticalEventIcon = isSolvedEvent && (
        <div
            className={cx(classes.criticalEventIconWrapper, {
                [classes.criticalIconWrapperShortOp]: isShortOp
            })}
        >
            <ReportProblem className={cx(classes.criticalEventIcon)} />
        </div>
    );

    const opBox = (
        <div
            className={cx(classes.opbox, {
                [classes.emergencyText]: isEmergency,
                [classes.emergencyBackground]: isEmergency && preparationStatusOpManage.includes(status),
                [classes.disabledBorder]: isClickedOrHovered || highlightedOp.id === id,
                [classes.deemphasized]: isDeemphasized,
                [classes.completed]: status === completedStatus,
                [classes.inProgress]: status === inProgressStatus,
                [classes.noBorderRadius]: isClickedOrHovered || highlightedOp.id === id
            })}
            data-testid={`OpBoxHorizontal-${id}-${isDeemphasized ? "published" : "new"}`}
            role={"button"}
            tabIndex={0}
            onClick={handleClick}
            onKeyDown={handleClick}
        >
            {disciplineColor}
            <div className={classes.contentWrapper}>
                {showContent && content}
                {(medCleared || isLocked) && (
                    <div
                        className={cx(classes.iconsTopRight, {
                            [classes.iconsWithEdit]: edit?.isEditIconVisible
                        })}
                    >
                        {medCleared && <CheckCircle className={classes.checkIcon} data-testid="medical-clearance" />}
                        {isLocked && <Lock className={classes.lockIcon} data-testid="lockedIcon" />}
                    </div>
                )}
                {edit?.isEditIconVisible && (
                    <div className={classes.editButton} title={!edit?.isEditable ? t("OpManagement.notEditable") : null}>
                        <IconButton
                            className={cx({[classes.deactivated]: !edit?.isEditable})}
                            data-testid={`edit-button-${id}-${edit?.isEditable ? "active" : "disabled"}`}
                            disabled={!edit?.isEditable}
                            size="small"
                            onClick={handleClickEdit}
                        >
                            <Create className={classes.icon} />
                        </IconButton>
                    </div>
                )}
            </div>
            <OpTimeLine duration={duration} />
            {criticalEventIcon}
        </div>
    );

    const handleOverlapMouseLeave = () => {
        dispatch(setOverlapHoveredOp(""));
    };

    const handleOverlapMouseEnter = () => {
        dispatch(setOverlapHoveredOp(id));
    };

    const handleClickOverlapIcon = (e) => {
        e.stopPropagation();
        dispatch(setOverlapClickedOp(id));
    };

    const handleClickaway = () => {
        if (overlapClickedOp === id) {
            dispatch(setOverlapClickedOp(""));
        }
    };

    const adjustLeft = adjustIndex > 0 ? adjustIndex * 16 : 0;

    return (
        <ClickAwayListener onClickAway={handleClickaway}>
            <Box>
                {hasOverlap && (
                    <Box
                        className={classes.overlapIcon}
                        left={`calc(${startPercent}% + ${adjustLeft - 4}px)`}
                        top={topPosition - 6 + "px"}
                        onClick={handleClickOverlapIcon}
                        onMouseEnter={handleOverlapMouseEnter}
                        onMouseLeave={handleOverlapMouseLeave}
                    >
                        <OpOverlapIcon style={cx({[classes.overlapActive]: overlapClickedOp === id})} />
                    </Box>
                )}
                <div
                    className={cx(classes.root, classes.transparentBorder, {
                        [classes.rootSelectedOp]: isClickedOrHovered && !isEmergency,
                        [classes.rootSelectedOpEmergency]: isClickedOrHovered && isEmergency,
                        [classes.rootHover]: isClickedOrHovered && !isEmergency,
                        [classes.rootHoverEmergency]: isClickedOrHovered && isEmergency,
                        [classes.rootHighlighted]: highlightedOp.id === id && !isEmergency,
                        [classes.rootHighlightedEmergency]: highlightedOp.id === id && isEmergency,
                        [classes.shortOp]: isShortOp && !hoveredState,
                        [classes.shortOpHovered]: isShortOp && hoveredState,
                        [classes.hide]: isDragging,
                        [classes.overlapHovered]: hasOverlap && (overlapHoveredOp === id || overlapClickedOp === id)
                    })}
                    data-opid={id}
                    data-testid="h-op-box"
                    ref={!isDeemphasized && edit?.isEditIconVisible && edit?.isEditable ? attach : null}
                    style={{
                        top: `${topPosition}px`,
                        left: `calc(${startPercent}% + 1px)`,
                        height: barHeight + "rem",
                        width: `calc(${endPercent - startPercent}% - 1px)`,
                        WebkitMaskImage: gradientCss,
                        maskImage: gradientCss
                    }}
                    onMouseDownCapture={(e) => e.stopPropagation()}
                    onMouseEnter={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                >
                    {isDragging ? (
                        opBox
                    ) : (
                        <Tooltip classes={{tooltip: classes.tooltip}} placement="right-start" title={isShortOp ? "" : content}>
                            {opBox}
                        </Tooltip>
                    )}
                </div>
            </Box>
        </ClickAwayListener>
    );
};

OpBoxHorizontal.propTypes = {
    shape: PropTypes.shape({
        topPosition: PropTypes.number.isRequired,
        startPercent: PropTypes.number.isRequired,
        endPercent: PropTypes.number.isRequired,
        barHeight: PropTypes.number.isRequired // in rem
    }),
    contents: PropTypes.shape({
        team: PropTypes.object,
        patient: PropTypes.object,
        intervention: PropTypes.string,
        duraCpb: PropTypes.object,
        station: PropTypes.string,
        medCleared: PropTypes.bool,
        surgeonPresenting: PropTypes.object,
        startOpTime: PropTypes.string,
        isLocked: PropTypes.bool.isRequired
    }).isRequired,
    edit: PropTypes.shape({
        isEditIconVisible: PropTypes.bool,
        isEditable: PropTypes.bool
    }),
    showDiscipline: PropTypes.bool,
    onClick: PropTypes.func,
    isDeemphasized: PropTypes.bool.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    isSearch: PropTypes.bool,
    id: PropTypes.string.isRequired,
    styles: PropTypes.object,
    status: PropTypes.string.isRequired,
    priority: PropTypes.number,
    duration: PropTypes.shape({
        duraRoomLockPost: PropTypes.shape({
            refEnd: PropTypes.number
        })
    }),
    procedureCode: PropTypes.string.isRequired,
    hcServiceId: PropTypes.string.isRequired,
    hasOverlap: PropTypes.bool.isRequired,
    adjustIndex: PropTypes.number.isRequired
};

const isOpEqual = (prevProps, nextProps) => {
    return (
        prevProps.topPosition === nextProps.topPosition && // Room
        prevProps.startPercent === nextProps.startPercent &&
        prevProps.endPercent === nextProps.endPercent &&
        prevProps.isDeemphasized === nextProps.isDeemphasized &&
        prevProps.showDiscipline === nextProps.showDiscipline &&
        prevProps.isSearch === nextProps.isSearch &&
        prevProps.edit?.isEditIconVisible === nextProps.edit?.isEditIconVisible &&
        prevProps.edit?.isEditable === nextProps.edit?.isEditable &&
        prevProps.status === nextProps.status &&
        prevProps.contents === nextProps.contents
    );
};
export default memo(OpBoxHorizontal, isOpEqual);
