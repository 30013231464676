import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    title: {
        height: "6rem",
        width: "100%",
        padding: "40px 30px",
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: "23px",
        borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    content: {
        padding: "30px"
    },

    empty: {
        padding: "30px"
    },

    subtitle: {
        height: "50px",
        width: "100%",
        position: "relative",
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        marginBottom: "30px"
    },

    all: {
        height: "100%",
        lineHeight: "100%",
        borderBottom: `4px solid ${theme.palette.primary.main}`,
        padding: theme.spacing("m"),
        position: "absolute"
    }
}));
