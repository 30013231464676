import {combineReducers} from "redux";

import tickerReducer from "./ticker_reducer.js";
import userNotificationsReducer from "./user_notifications_reducer.js";

const reducers = {
    ticker: tickerReducer,
    userNotifications: userNotificationsReducer
};

export default combineReducers(reducers);
