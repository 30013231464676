/**
 * @type {number} Timeout in milliseconds to hide notifications from display
 */
const NOTIFICATION_DISPLAY_TIMEOUT = 1000 * 30;

/**
 * @type {number} Interval in milliseconds to poll for new notifications
 */
const POLLING_INTERVAL = 1000 * 60;

/**
 * @type {Object}   Notification event names
 */
const EVENT = {
    CalculateNewPlan: "CalculateNewPlan",
    RegisterEmergency: "RegisterEmergency",
    SuccessAutomaticRequestSchedulerPlanCritical: "SuccessAutomaticRequestSchedulerPlanCritical"
};

/**
 * @type {string[]} Event names that should be grouped together in a display
 */
const GROUPED_EVENTS = [EVENT.SuccessAutomaticRequestSchedulerPlanCritical];

/**
 * @type {Object}   Types of reference data used in notifications
 */
const REFERENCE_TYPE = {
    PATIENT: "Patient",
    SERVICE_REQUEST: "ServiceRequest",
    LOCATION: "Location"
};

/**
 * @type {Object}   Notification severities
 */
const SEVERITY = {
    LOW: "low",
    NORMAL: "normal",
    HIGH: "high"
};

const STATUS = {
    NEW: "new",
    DISPLAYED: "displayed",
    READ: "read",
    DELETED: "obsolete"
};

export {GROUPED_EVENTS, NOTIFICATION_DISPLAY_TIMEOUT, POLLING_INTERVAL, REFERENCE_TYPE, SEVERITY, STATUS};
