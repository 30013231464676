import {DoneAll, Info, List, Warning} from "@mui/icons-material";

import {SEVERITY} from "../../../../config/event_config";

export default {
    // requests
    [SEVERITY.INFO]: Info,
    [SEVERITY.ERROR]: Warning,
    [SEVERITY.SUCCESS]: DoneAll,

    Default: List
};
