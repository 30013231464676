// @ts-check

import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import useTimer from "../../../hooks/use_timer";
import {Notification} from "../../notification";
import useStyles from "./user_notification.styles";
import UserNotificationDetails from "./user_notification_details";

const ALL = "all";

/**
 * Render UserNotification component
 *
 * @param {Object} props
 * @param {NotificationType} props.notification
 * @param {Number} [props.timeout]
 * @param {Function} props.onClose
 * @return {JSX.Element}
 */
const UserNotification = ({notification, timeout, onClose}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const {formatFromISO} = useContext(DateContext);

    // State
    const [isVisible, setVisible] = useState(true);

    const handleClose = (confirmed = true) => {
        setVisible(false);

        setTimeout(() => {
            onClose(notification._id, confirmed);
        }, 1000);
    };

    useTimer(() => {
        if (timeout) {
            handleClose(false);
        }
    }, timeout);

    const hcServices = notification?.references?.length ? notification.references.filter((item) => item.type === "HealthcareService") : [];
    const hcServiceFormat =
        hcServices.length && hcServices[0].reference === ALL
            ? t("Notification.allDisciplines")
            : hcServices.map((el) => t(`HealthcareService.${el.reference}`)).join(", ");

    const timestamp = `${formatFromISO(notification.createdAt, DATE_FORMATS.DATE_SHORT)} | ${formatFromISO(
        notification.createdAt,
        DATE_FORMATS.TIME
    )}`;

    return (
        <div
            className={cx(classes.root, {
                [classes.collapsed]: !isVisible
            })}
        >
            <Notification
                event={notification.event}
                open
                timestamp={timestamp}
                titleText={t([notification.title, "Notification.title_default"])}
                onClose={handleClose}
            >
                {notification.references && notification.references.length ? (
                    <UserNotificationDetails className={classes.details} references={notification.references} />
                ) : null}
                <div>{t([notification.message, "Notification.text_default"], {discipline: hcServiceFormat})}</div>
            </Notification>
        </div>
    );
};

UserNotification.propTypes = {
    notification: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        event: PropTypes.string.isRequired,
        references: PropTypes.arrayOf(PropTypes.shape({})),
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired
    }).isRequired,
    timeout: PropTypes.number,
    onClose: PropTypes.func.isRequired
};

export default UserNotification;
