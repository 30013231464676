import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import useInterval from "../../../hooks/use_interval";
import {selectCurrentOrganizationId, selectCurrentTimezone, selectCurrentUserEmail} from "../../../redux/app_selectors";
import {displayUserNotificationAction, handleUserNotificationAction} from "../../notification";
import UserNotificationsOverview from "../components/user_notifications_overview";
import {POLLING_INTERVAL, STATUS} from "../notification_constants";
import {displayUserNotificationsAction, handleUserNotificationsAction, loadUserNotificationsAction} from "../notifications_actions";
import {selectActiveNotifications} from "../user_notifications_selectors";
import {compareNotification} from "../utils/notification";

/**
 * UserNotificationsOverviewContainer
 *
 * Display notifications for the current user.
 *  - New notifications will marked as "displayed" at their first render.
 *  - Notifications may contain patient or surgeon data, so we de-anonymize such data.
 *
 * @return {JSX.Element}
 */
const UserNotificationsOverviewContainer = () => {
    const dispatch = useDispatch();
    const organizationId = useSelector(selectCurrentOrganizationId);
    const currentUserEmail = useSelector(selectCurrentUserEmail);
    const currentTimezone = useSelector(selectCurrentTimezone);
    const notifications = useSelector(selectActiveNotifications);

    const sortedNotifications = useMemo(
        () =>
            notifications
                .map((notification) => ({
                    ...notification,
                    isNew:
                        notification.recipients.findIndex(({email, status}) => email === currentUserEmail && status !== STATUS.READ) !== -1,
                    isRead:
                        notification.recipients.findIndex(({email, status}) => email === currentUserEmail && status === STATUS.READ) !== -1
                }))
                .sort((a, b) => compareNotification(a, b)),
        [notifications]
    );

    useInterval(
        // poll for notifications
        () => {
            if (organizationId && currentUserEmail) {
                dispatch(loadUserNotificationsAction(organizationId, currentUserEmail));
            }
        },
        {delay: POLLING_INTERVAL, immediately: true}
    );

    useEffect(() => {
        // mark new notifications as "displayed"
        notifications.forEach((notification) => {
            if (notification.recipients) {
                notification.recipients
                    .filter(({email, status}) => email === currentUserEmail && status === STATUS.NEW)
                    .forEach(() => {
                        dispatch(displayUserNotificationAction(notification._id, currentUserEmail));
                        dispatch(displayUserNotificationsAction(notification._id, currentUserEmail));
                    });
            }
        });
    }, [notifications]);

    const handleNotificationDelete = (id) => {
        dispatch(handleUserNotificationAction(id, currentUserEmail, "delete"));
        dispatch(handleUserNotificationsAction(id, currentUserEmail, "delete"));
    };

    const handleNotificationRead = (id) => {
        dispatch(handleUserNotificationAction(id, currentUserEmail, "read"));
        dispatch(handleUserNotificationsAction(id, currentUserEmail, "read"));
    };

    const handleNotificationUnread = (id) => {
        dispatch(handleUserNotificationAction(id, currentUserEmail, "new"));
        dispatch(handleUserNotificationsAction(id, currentUserEmail, "new"));
    };

    return (
        <UserNotificationsOverview
            notifications={sortedNotifications}
            timezone={currentTimezone}
            onNotificationConfirm={handleNotificationRead}
            onNotificationDelete={handleNotificationDelete}
            onNotificationUnread={handleNotificationUnread}
        />
    );
};

export default UserNotificationsOverviewContainer;
