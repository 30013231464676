import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

import {REFERENCE_TYPE} from "../notification_constants";
import useStyles from "./user_notification_details.styles";

const UserNotificationDetails = ({className, references}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();

    const renderDetail = (key, type, reference) => {
        if (type === REFERENCE_TYPE.PATIENT) {
            return (
                <div className={classes.entry} key={key}>
                    {reference}
                </div>
            );
        }

        if (type === REFERENCE_TYPE.LOCATION) {
            return (
                <div className={classes.entry} key={key}>
                    {t("UserNotificationDetails.room")}&nbsp;<strong>{reference}</strong>
                </div>
            );
        }

        return null;
    };

    return <div className={className}>{references.map(({reference, type}) => renderDetail(`${type}-${reference}`, type, reference))}</div>;
};

UserNotificationDetails.propTypes = {
    className: PropTypes.string,
    references: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            reference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        })
    ).isRequired
};

export default UserNotificationDetails;
