// @ts-check
import {makeStyles} from "tss-react/mui";

export default makeStyles()((/** @type AllCustomTheme */ theme) => ({
    publishRoot: {
        display: "flex",
        alignItems: "center"
    },
    editByWrapper: {
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing("m")
    },
    editByLabel: {
        fontSize: "12px",
        fontWeight: theme.typography.fontWeightLight,
        marginRight: theme.spacing("s")
    },
    editUserIcon: {
        width: theme.spacing("l"),
        height: theme.spacing("l"),
        borderRadius: "50%",
        background: theme.palette.grey[200],
        display: "flex",
        justifyContent: "center",
        border: `2px solid ${theme.palette.primary.main}`,
        lineHeight: "1.75rem",
        fontSize: "12px"
    },
    button: {
        fontSize: "0.875rem"
    }
}));
