import {createSelector} from "reselect";

import {NOTIFICATION_DISPLAY_TIMEOUT, STATUS} from "./notification_constants";

const selectNotificationsState = (state) => state.notifications;

const selectUserNotificationsState = (state) => selectNotificationsState(state).userNotifications;

const selectError = (state) => selectUserNotificationsState(state).error;

const selectStatus = (state) => selectUserNotificationsState(state).status;

const selectNotifications = (state) => selectUserNotificationsState(state).notifications || [];

const selectActiveNotifications = createSelector(selectNotifications, (notifications) =>
    notifications.filter(
        (notification) => notification.recipients && notification.recipients.find((recipient) => recipient.status !== STATUS.DELETED)
    )
);

const selectNewNotifications = createSelector(selectNotifications, (notifications) =>
    notifications.filter(
        (notification) =>
            notification._popup &&
            notification.recipients &&
            notification.recipients.find(
                (recipient) =>
                    recipient.status === STATUS.NEW ||
                    (recipient.status === STATUS.DISPLAYED &&
                        Math.round(new Date() - new Date(recipient.displayedAt)) < NOTIFICATION_DISPLAY_TIMEOUT)
            )
    )
);

const selectUnreadNotifications = createSelector(selectNotifications, (notifications) =>
    notifications.filter(
        (notification) =>
            notification.recipients &&
            notification.recipients.find((recipient) => recipient.status === STATUS.NEW || recipient.status === STATUS.DISPLAYED)
    )
);

const selectUnreadNotificationsCount = createSelector(selectUnreadNotifications, (notifications) => notifications.length);

export {selectNotifications, selectActiveNotifications, selectNewNotifications, selectUnreadNotificationsCount, selectError, selectStatus};
