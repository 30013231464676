import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    isNew: {
        fontWeight: theme.typography.fontWeightBold
    },
    menu: {
        boxShadow: theme.shadows[4]
    }
}));
