/**
 * @fileoverview redux action types for notification
 */

const ActionTypes = {
    DISPLAY_USER_NOTIFICATION_REQUEST: "notification/DISPLAY_USER_NOTIFICATION_REQUEST",
    DISPLAY_USER_NOTIFICATION_SUCCESS: "notification/DISPLAY_USER_NOTIFICATION_SUCCESS",
    DISPLAY_USER_NOTIFICATION_FAILURE: "notification/DISPLAY_USER_NOTIFICATION_FAILURE",

    HANDLE_USER_NOTIFICATION_REQUEST: "notification/HANDLE_USER_NOTIFICATION_REQUEST",
    HANDLE_USER_NOTIFICATION_SUCCESS: "notification/HANDLE_USER_NOTIFICATION_SUCCESS",
    HANDLE_USER_NOTIFICATION_FAILURE: "notification/HANDLE_USER_NOTIFICATION_FAILURE"
};

export default ActionTypes;
