import PropTypes from "prop-types";
import React from "react";
import {useSelector} from "react-redux";

import NotificationsBadge from "../components/notifications_badge";
import {selectUnreadNotificationsCount} from "../user_notifications_selectors";

const UserNotificationsBadgeContainer = ({active, className, fontSize, cx}) => {
    const count = useSelector(selectUnreadNotificationsCount);

    return <NotificationsBadge active={active} classes={{icon: className}} count={count} cx={cx} fontSize={fontSize} />;
};

UserNotificationsBadgeContainer.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    fontSize: PropTypes.string,
    cx: PropTypes.func.isRequired
};

export default UserNotificationsBadgeContainer;
