import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    active: {
        color: theme.palette.primary.main
    },
    inactive: {
        color: theme.palette.grey[500]
    },
    badge: {
        fontSize: "0.75rem"
    }
}));
