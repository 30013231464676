// @ts-check
import {Info} from "@mui/icons-material";
import {Tooltip} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {selectInfo3} from "../../../pages/day_view/day_view_selectors";
import {formatInfo3} from "../../info_layer/helpers";
import {selectStandardNamesArray} from "../../private_data/private_data_selectors";
import getPersonIdsFromInfo from "../../private_data/utils/get_person_ids_from_info";
import useStyles from "./info_ticker.styles";

/**
 * Render InfoTicker
 *
 * @return {React.ReactElement}
 */
const InfoTicker = () => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const info3 = useSelector(selectInfo3);

    const practitionerNamesList = useSelector(
        selectStandardNamesArray({ids: getPersonIdsFromInfo(info3), type: "practitioner"}),
        // To avoid error "Maximum update depth exceeded"
        (prev, next) => prev.length === next.length && prev.every((item, index) => shallowEqual(item, next[index]))
    );

    const [isOverflowed, setIsOverflow] = useState(false);
    /** @type {null|{current: HTMLDivElement}} */
    const infoElementRef = useRef(null);

    const compareSize = () => {
        if (infoElementRef?.current) {
            const compare = infoElementRef.current.scrollWidth > infoElementRef.current.clientWidth;
            setIsOverflow(compare);
        }
    };

    // check the overflow initially and on every resize event.
    // practitionerNamesList needs to be in the dependency because the length of the element will change after the names rendered
    useEffect(() => {
        compareSize();
        window.addEventListener("resize", compareSize);
        return () => {
            window.removeEventListener("resize", compareSize);
        };
    }, [practitionerNamesList]);

    const {
        TEXT_PUNCTUATION: {HYPHEN, VERTICAL_SLASH}
    } = config;

    const mergedInfo3List = info3 ? formatInfo3(info3) : [];

    const duties = mergedInfo3List?.map((duty) => {
        const pracNames = duty.practitionerIds.map(
            (practitionerId) => practitionerNamesList.find((name) => name.id === practitionerId)?.name
        );
        return (
            <div className={classes.infoTickerItem} key={duty.role}>
                {t(`Info3.${duty.role}`)}:&nbsp; <div className={classes.name}>{pracNames.join(VERTICAL_SLASH) || HYPHEN}</div>
                &nbsp;
            </div>
        );
    });
    return (
        <div className={classes.infoTicker}>
            <Info className={classes.infoIcon} />
            <Tooltip
                arrow
                classes={{tooltip: classes.tooltip}}
                disableHoverListener={!isOverflowed}
                title={<div className={classes.tooltipContent}>{duties}</div>}
            >
                <div className={classes.infoTickerItemWrapper} ref={infoElementRef}>
                    {duties}
                </div>
            </Tooltip>
        </div>
    );
};

export default React.memo(InfoTicker);
