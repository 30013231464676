import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import useInterval from "../../../hooks/use_interval";
import {selectCurrentOrganizationId, selectCurrentUserEmail} from "../../../redux/app_selectors";
import {displayUserNotificationAction, handleUserNotificationAction} from "../../notification";
import UserNotificationsSnackbar from "../components/user_notifications_snackbar";
import {GROUPED_EVENTS, NOTIFICATION_DISPLAY_TIMEOUT, POLLING_INTERVAL, STATUS} from "../notification_constants";
import {displayUserNotificationsAction, handleUserNotificationsAction, loadUserNotificationsAction} from "../notifications_actions";
import {selectNewNotifications} from "../user_notifications_selectors";
import {compareNotification, groupNotificationsByEvent} from "../utils/notification";

/**
 * UserNotificationsSnackbarContainer
 *
 * Load, prepare and display notifications for the current user.
 *  - Uses a positive list of events to group notifications about the same event, notifications will be sorted for display.
 *  - New notifications will marked as "displayed" at their first render.
 *  - Notifications may contain patient or surgeon data, so we de-anonymize such data.
 *
 * @return {JSX}
 */
const UserNotificationsSnackbarContainer = () => {
    const dispatch = useDispatch();
    const organizationId = useSelector(selectCurrentOrganizationId);
    const currentUserEmail = useSelector(selectCurrentUserEmail);
    const notifications = useSelector(selectNewNotifications);

    const sortedNotifications = useMemo(
        // group notifications by events, sort groups to display latest message on top
        () => Object.values(groupNotificationsByEvent(notifications, GROUPED_EVENTS)).sort((a, b) => compareNotification(a[0], b[0])),
        [notifications]
    );

    useInterval(
        // poll for notifications
        () => {
            if (organizationId && currentUserEmail) {
                dispatch(loadUserNotificationsAction(organizationId, currentUserEmail));
            }
        },
        {delay: POLLING_INTERVAL, immediately: true}
    );

    useEffect(() => {
        // mark new notifications as "displayed"
        notifications.forEach((notification) => {
            if (notification.recipients) {
                notification.recipients
                    .filter(({email, status}) => email === currentUserEmail && status === STATUS.NEW)
                    .forEach(() => {
                        dispatch(displayUserNotificationAction(notification._id, currentUserEmail));
                        dispatch(displayUserNotificationsAction(notification._id, currentUserEmail));
                    });
            }
        });
    }, [notifications]);

    const handleNotificationClose = (id, confirmed) => {
        if (confirmed) {
            // mark notification as "read"
            dispatch(handleUserNotificationAction(id, currentUserEmail, "read"));
            dispatch(handleUserNotificationsAction(id, currentUserEmail, "read"));
        }
    };

    return (
        <UserNotificationsSnackbar
            notifications={sortedNotifications}
            timeout={NOTIFICATION_DISPLAY_TIMEOUT}
            onNotificationClose={handleNotificationClose}
        />
    );
};

export default UserNotificationsSnackbarContainer;
