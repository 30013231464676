/**
 * @fileoverview API for notification
 */

import axios from "../../middleware/axios.middelware";

const BASE_URL = "/orchestrator/notifications";

/**
 * mark a notification as displayed
 * @param {String} notificationId   the notification id
 * @param {String} email            the user's email address
 * @param {String} organizationId
 * @return {Promise}
 */
function updateUserNotificationAsDisplayed(notificationId, email, organizationId) {
    return axios.patch(`${BASE_URL}/${encodeURI(notificationId)}/recipient/${encodeURI(email)}/displayed?organizationId=${organizationId}`);
}

/**
 * mark a notification as read
 * @param {String} notificationId   the notification id
 * @param {String} email            the user's email address
 * @param {String} actionType       "new", "read" or "delete"
 * @param {String} organizationId
 * @return {Promise}
 */
function updateUserNotificationAPI(notificationId, email, actionType, organizationId) {
    if (actionType === "delete") {
        return axios.delete(`${BASE_URL}/${encodeURI(notificationId)}/recipient/${encodeURI(email)}?organizationId=${organizationId}`);
    } else {
        return axios.patch(
            `${BASE_URL}/${encodeURI(notificationId)}/recipient/${encodeURI(email)}/${actionType}?organizationId=${organizationId}`
        );
    }
}

export {updateUserNotificationAsDisplayed, updateUserNotificationAPI};
