// @ts-check
import {number, string} from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

import useStyles from "../op_backlog_view.styles";
/**
 *
 * @param {Object} props
 * @param {string} props.labelKey
 * @param {number} props.count
 * @return {React.ReactElement}
 */
const TabLabel = ({labelKey, count}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    return (
        <div>
            {t(`Status.${labelKey}`)}
            <span className={classes.tabCount}>{t("OpBacklogView.count", {count})}</span>
        </div>
    );
};
TabLabel.propTypes = {
    labelKey: string.isRequired, // translation key
    count: number.isRequired
};
export default React.memo(TabLabel);
