/**
 * @fileoverview redux action types for notifications
 */

const ActionTypes = {
    LOAD_TICKER_REQUEST: "notifications/LOAD_TICKER_REQUEST",
    LOAD_TICKER_SUCCESS: "notifications/LOAD_TICKER_SUCCESS",
    LOAD_TICKER_FAILURE: "notifications/LOAD_TICKER_FAILURE",

    LOAD_USER_NOTIFICATIONS_REQUEST: "notifications/LOAD_USER_NOTIFICATIONS_REQUEST",
    LOAD_USER_NOTIFICATIONS_SUCCESS: "notifications/LOAD_USER_NOTIFICATIONS_SUCCESS",
    LOAD_USER_NOTIFICATIONS_FAILURE: "notifications/LOAD_USER_NOTIFICATIONS_FAILURE",

    HANDLE_USER_NOTIFICATION: "notifications/HANDLE_USER_NOTIFICATION",
    DISPLAY_USER_NOTIFICATION: "notifications/DISPLAY_USER_NOTIFICATION"
};

export default ActionTypes;
