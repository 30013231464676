// @ts-check
import PropTypes from "prop-types";
import React from "react";

import UserNotification from "./user_notification";
import useStyles from "./user_notifications_snackbar.styles";

/**
 * Render UserNotificationsSnackbar component
 * @param {Object} props
 * @param {Array<Array<NotificationType>>} props.notifications
 * @param {Number} props.timeout
 * @param {Function} props.onNotificationClose
 * @return {React.ReactElement}
 */
const UserNotificationsSnackbar = ({notifications, timeout, onNotificationClose}) => {
    const {classes} = useStyles();

    if (!notifications.length) {
        return null;
    }
    return (
        <div className={classes.root}>
            <ul className={classes.groupList}>
                {notifications
                    .filter((notificationGroup) => notificationGroup.length)
                    .map((notificationGroup) => (
                        <li key={notificationGroup[0]._id}>
                            <ul className={classes.group}>
                                {notificationGroup.map((notification) => (
                                    <li className={classes.item} key={notification._id}>
                                        <UserNotification notification={notification} timeout={timeout} onClose={onNotificationClose} />
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

UserNotificationsSnackbar.propTypes = {
    notifications: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string.isRequired,
                references: PropTypes.arrayOf(PropTypes.shape({})),
                severity: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                message: PropTypes.string.isRequired
            })
        )
    ).isRequired,
    timeout: PropTypes.number,
    onNotificationClose: PropTypes.func.isRequired
};

export default UserNotificationsSnackbar;
