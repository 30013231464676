// @ts-check

import {bool, object, string} from "prop-types";
import React, {useContext} from "react";
import {useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {formatObservation} from "../../../utils/patient_helpers";
import {selectPatientInfo} from "../../private_data/private_data_selectors";
import buildPatientInfo from "../../private_data/utils/build_patient_info";
import useStyles from "../op_box_horizontal.styles";

/**
 * render HeaderRow component in the horizontal OP box, include op start time and patient details
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {ObservationsPatient} props.observations
 * @param {boolean} props.isEditIconVisible
 * @param {string} props.startOpTime
 * @return {React.ReactElement}
 */
const HeaderRow = ({id, observations, isEditIconVisible, startOpTime}) => {
    const {classes, cx} = useStyles();
    const {getLuxonToken} = useContext(DateContext);

    const {
        TEXT_PUNCTUATION: {VERTICAL_SLASH}
    } = config;

    const patientInfo = useSelector((state) => selectPatientInfo(state, {id}));

    const {name, birthDate, gender} = buildPatientInfo(patientInfo, id, getLuxonToken(DATE_FORMATS.BIRTH_DATE_FORMAT));
    const patientDetails = [birthDate, gender, formatObservation(observations?.weight), formatObservation(observations?.height)];
    return (
        <div
            className={cx(classes.contentRow, {
                [classes.paddingRight]: isEditIconVisible
            })}
        >
            <strong>{startOpTime}</strong>&nbsp;
            <strong>{name}</strong>&nbsp;
            {patientDetails.join(` ${VERTICAL_SLASH} `)}
        </div>
    );
};
HeaderRow.propTypes = {
    id: string.isRequired,
    observations: object.isRequired,
    isEditIconVisible: bool,
    startOpTime: string.isRequired
};
export default HeaderRow;
