import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    opbox: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        borderRadius: "4px",
        boxShadow: theme.shadows[2],
        cursor: "pointer",
        backgroundColor: "#fff",
        overflow: "hidden"
    },
    noBorderRadius: {
        borderRadius: 0
    },
    inProgress: {
        backgroundColor: theme.custom.progress.light
    },
    completed: {
        backgroundColor: theme.custom.post.light
    },
    emergencyText: {
        color: theme.custom.emergency.main
    },
    emergencyBackground: {
        background: theme.custom.emergency.light
    },
    emergencyBorder: {
        border: `2px solid ${theme.custom.emergency.main}`
    },
    disabledBorder: {
        border: "none"
    },
    deemphasized: {
        opacity: 0.6
    },
    contentWrapper: {
        height: "calc(100% - 0.5rem)",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        padding: "0.125rem 0"
    },
    contentRowBorder: {
        borderTop: `dotted 0.5px ${theme.palette.grey[500]}`
    },
    contentRowBorderEmergency: {
        borderTop: `dotted 0.5px ${theme.custom.emergency.main}`
    },
    contentRow: {
        fontSize: "0.688rem",
        padding: "0 0.25rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    paddingRight: {
        paddingRight: "0.75rem"
    },
    bold: {
        fontWeight: theme.typography.fontWeightBold
    },
    discipline: {
        width: "100%",
        height: "0.25rem"
    },
    root: {
        position: "absolute",
        background: "transparent",
        borderRadius: "4px",
        zIndex: theme.custom.zIndex.canvas + 5
    },
    transparentBorder: {
        border: "2px solid transparent"
    },
    rootHover: {
        "&:hover": {
            border: `2px solid ${theme.palette.primary.main}`
        }
    },
    rootHoverEmergency: {
        "&:hover": {
            border: `2px solid ${theme.custom.emergency.main}`
        }
    },
    rootSelectedOp: {
        border: `2px solid ${theme.palette.primary.main}`
    },
    rootSelectedOpEmergency: {
        border: `2px solid ${theme.custom.emergency.main}`
    },
    editButton: {
        "position": "absolute",
        "right": 0,
        "top": 0,
        "padding": "1px",
        "color": theme.custom.progress.main,
        "& button": {
            padding: "3px 0"
        }
    },
    deactivated: {
        "& svg": {
            color: theme.palette.grey[400]
        }
    },
    icon: {
        "fontSize": "0.8rem",
        "color": theme.palette.primary.main,
        "borderRadius": "0.5rem",
        "&:hover": {
            backgroundColor: theme.palette.primary.light
        }
    },
    lockedIcon: {
        position: "absolute",
        top: theme.spacing("m"),
        right: "-1px",
        width: "1.5rem",
        height: "1.5rem",
        borderTopLeftRadius: "0.75rem",
        borderBottomLeftRadius: "0.75rem",
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: theme.shadows[1],
        backgroundColor: theme.palette.primary.contrastText
    },
    hidden: {
        display: "none"
    },
    rootHighlighted: {
        border: `4px solid ${theme.palette.primary.main}`
    },
    rootHighlightedEmergency: {
        border: `4px solid ${theme.custom.emergency.main}`
    },
    shortOp: {
        width: "1rem !important"
    },
    shortOpHovered: {
        width: "15rem !important",
        transition: "width 0.3s ease-in"
    },
    tooltip: {
        minWidth: "10rem",
        maxWidth: "50rem"
    },
    criticalEventIcon: {
        color: theme.palette.common.white,
        fontSize: "0.688rem",
        marginBottom: "0.125rem"
    },
    criticalEventIconWrapper: {
        right: "0.25rem",
        top: "1.25rem",
        background: theme.custom.emergency.main,
        width: theme.spacing("m"),
        height: theme.spacing("m"),
        position: "absolute",
        borderRadius: theme.spacing("m"),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    criticalIconWrapperShortOp: {
        top: "1.25rem",
        width: "0.8rem",
        height: "0.8rem",
        right: 0
    },
    hide: {
        display: "none"
    },
    italic: {
        fontStyle: "italic"
    },
    label: {
        "&:after": {
            display: "inline",
            content: '":"'
        }
    },
    iconsTopRight: {
        "position": "absolute",
        "right": "0.2rem",
        "top": "0.25rem",
        "display": "flex",
        "justifyContent": "flex-end",
        "& svg": {
            width: "16px",
            height: "16px",
            background: "#f7f7f7",
            borderRadius: "50%"
        }
    },
    iconsWithEdit: {
        right: "1.2rem"
    },
    overlapIcon: {
        position: "absolute",
        zIndex: theme.custom.zIndex.canvas + 50,
        height: "18px",
        cursor: "pointer"
    },

    overlapActive: {
        background: theme.palette.warning.light
    },
    overlapHovered: {
        zIndex: theme.custom.zIndex.canvas + 35
    },
    checkIcon: {
        color: theme.palette.success.main,
        padding: "1px"
    },
    lockIcon: {
        marginLeft: "2px",
        color: theme.palette.text.secondary
    }
}));
