// @ts-check

import config from "../../../config/config.json";
import {format, getDateTimeFromISO} from "../../utils/luxon_helpers";
import getPractitionerIdsFromScheduleOpByCategory from "../private_data/utils/get_practitioner_ids_from_schedule_ops_by_category";

const medClearance = "next_medClearance";

/**
 * format the content of OpBoxHorizontal
 *
 * @param {object} params
 * @param {NextOrOpTeam} params.team
 * @param {ParticipantCategoriesSettings} params.participantCategories
 * @param {PlanBoxPatient} params.patient
 * @param {string} params.intervention
 * @param {InternalTimestampsOpData} params.internalTimestamps
 * @param {boolean} params.isCpbVisible
 * @param {Array<MedicalApproval>} params.medicalApproval
 * @param {SurgeonPresenting} params.surgeonPresenting
 * @param {string} params.luxonTokenTime
 * @param {boolean} params.isLocked
 * @return {OpBoxHorizontalContents}
 */
export const formatContents = ({
    team,
    participantCategories,
    patient,
    intervention,
    internalTimestamps,
    isCpbVisible,
    medicalApproval,
    surgeonPresenting,
    luxonTokenTime,
    isLocked
}) => {
    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;
    const preStartDT = internalTimestamps.duraRoomLockPre?.dtStart && getDateTimeFromISO(internalTimestamps.duraRoomLockPre.dtStart);

    return {
        team: getPractitionerIdsFromScheduleOpByCategory(team, participantCategories),
        patient,
        intervention,
        duraCpb: (isCpbVisible && internalTimestamps?.duraCpb) || null,
        medCleared: !!medicalApproval?.find((approval) => approval.type === medClearance)?.practitionerId,
        surgeonPresenting,
        startOpTime: preStartDT ? format(preStartDT, luxonTokenTime) : HYPHEN,
        isLocked
    };
};

/**
 *
 * @param {object} params
 * @param {boolean} params.isPublished
 * @param {boolean} params.isEditingByOther
 * @return {boolean}
 */
export const determineIsEditIconVisible = ({isPublished, isEditingByOther}) => {
    return !isPublished && !isEditingByOther;
};
