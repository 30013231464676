/**
 * @fileoverview redux reducer the user notifications
 */

import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import {STATUS as NOTIFICATION_STATUS} from "./notification_constants";
import ActionTypes from "./notifications_action_types";

const initialState = {
    notifications: [],
    status: STATUS.IDLE,
    error: null
};

const updateStatus = (notifications, notificationId, email, data) =>
    notifications.map((notification) => {
        if (notification._id !== notificationId || !notification.recipients) {
            return notification;
        }

        return {
            ...notification,
            recipients: notification.recipients.map((recipient) =>
                recipient.email !== email
                    ? recipient
                    : {
                          ...recipient,
                          ...data
                      }
            )
        };
    });

const handleUserNotification = (state, {notificationId, email, actionType}) => {
    let status = NOTIFICATION_STATUS.DISPLAYED;
    switch (actionType) {
        case "new":
            status = NOTIFICATION_STATUS.NEW;
            break;
        case "read":
            status = NOTIFICATION_STATUS.READ;
            break;
        case "delete":
            status = NOTIFICATION_STATUS.DELETED;
            break;
        default:
            break;
    }
    return {
        ...state,
        notifications: updateStatus(state.notifications, notificationId, email, {status, updatedAt: new Date()})
    };
};

const handleDisplay = (state, {notificationId, email}) => ({
    ...state,
    notifications: updateStatus(state.notifications, notificationId, email, {
        status: NOTIFICATION_STATUS.DISPLAYED,
        displayedAt: new Date()
    })
});

const handleLoadRequest = (state) => ({
    ...state,
    status: STATUS.PENDING,
    error: null
});

const handleLoadFailure = (state, {error}) => ({
    ...state,
    status: STATUS.REJECTED,
    error
});

const handleLoadSuccess = (state, {payload}) => ({
    ...state,
    status: STATUS.RESOLVED,
    error: null,
    notifications: payload
});

const handlers = {
    [ActionTypes.HANDLE_USER_NOTIFICATION]: handleUserNotification,
    [ActionTypes.DISPLAY_USER_NOTIFICATION]: handleDisplay,

    [ActionTypes.LOAD_USER_NOTIFICATIONS_REQUEST]: handleLoadRequest,
    [ActionTypes.LOAD_USER_NOTIFICATIONS_SUCCESS]: handleLoadSuccess,
    [ActionTypes.LOAD_USER_NOTIFICATIONS_FAILURE]: handleLoadFailure
};

export default createReducer(initialState, handlers);
