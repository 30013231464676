// @ts-check
import {makeStyles} from "tss-react/mui";

export default makeStyles()((/** @type AllCustomTheme */ theme) => ({
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "40px 40px 16px",
        fontSize: "23px",
        textTransform: "none",
        fontWeight: theme.typography.fontWeightMedium
    },
    text: {
        fontSize: theme.spacing("m"),
        marginBottom: theme.spacing("l"),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular
    },
    icon: {
        cursor: "pointer"
    }
}));
