// @ts-check
import {Close} from "@mui/icons-material";
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {func} from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {EVENTS} from "../../../../config/event_config";
import {requestScheduleAction} from "../../../pages/op_management/op_management_actions";
import {selectStatus} from "../../../pages/op_management/op_management_selectors";
import {selectCurrentOrganizationId} from "../../../redux/app_selectors";
import {PERMISSION, useSecurity} from "../../../utils/security";
import PermissionTooltip from "../../shared/permission_tooltip/permission_tooltip";
import useStyles from "./request_plan_dialog.styles";

/**
 * render RequestPlanDialog component
 *
 * @param {object} props
 * @param {function} props.onClose
 * @return {React.ReactElement}
 */
const RequestPlanDialog = ({onClose}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {isGranted} = useSecurity();

    const status = useSelector(selectStatus);
    const organizationId = useSelector(selectCurrentOrganizationId);

    const handleRequestPlan = () => {
        dispatch(requestScheduleAction(organizationId));
        onClose();
    };

    return (
        <>
            <DialogTitle className={classes.title} variant="h1">
                {t("RequestPlanDialog.title")}
                <Close className={classes.icon} onClick={() => onClose()} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.text}>{t("RequestPlanDialog.text")}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {status.transitions?.includes(EVENTS.ManualRequestSchedulerPlanUncritical) && (
                    <PermissionTooltip isAllowed={isGranted(PERMISSION.MODIFY_PLAN)}>
                        <Button variant="contained" onClick={handleRequestPlan}>
                            {t("RequestPlanDialog.requestPlan")}
                        </Button>
                    </PermissionTooltip>
                )}
            </DialogActions>
        </>
    );
};
RequestPlanDialog.propTypes = {
    onClose: func.isRequired
};
export default RequestPlanDialog;
