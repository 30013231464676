/**
 * @fileoverview redux actions for notification
 */

import {authUserFailureAction} from "../../redux/actions/index";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import ActionTypes from "./notification_action_types";
import {updateUserNotificationAPI, updateUserNotificationAsDisplayed} from "./notification_api";

const displayUserNotificationRequestAction = () => ({
    type: ActionTypes.DISPLAY_USER_NOTIFICATION_REQUEST
});

const displayUserNotificationSuccessAction = (payload) => ({
    type: ActionTypes.DISPLAY_USER_NOTIFICATION_SUCCESS,
    payload
});

const displayUserNotificationFailureAction = (error) => ({
    type: ActionTypes.DISPLAY_USER_NOTIFICATION_FAILURE,
    error
});

/**
 * display an user notifications
 * @param {String} notificationId
 * @param {String} email
 * @return {function}
 */
function displayUserNotificationAction(notificationId, email) {
    return function (dispatch, getState) {
        dispatch(displayUserNotificationRequestAction());
        const organizationId = selectCurrentOrganizationId(getState());

        updateUserNotificationAsDisplayed(notificationId, email, organizationId)
            .then(({data}) => {
                dispatch(displayUserNotificationSuccessAction(data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "update displayed notification error"}));
                } else {
                    dispatch(displayUserNotificationFailureAction(error.message));
                }
            });
    };
}

const handleUserNotificationRequestAction = () => ({
    type: ActionTypes.HANDLE_USER_NOTIFICATION_REQUEST
});

const handleUserNotificationSuccessAction = (payload) => ({
    type: ActionTypes.HANDLE_USER_NOTIFICATION_SUCCESS,
    payload
});

const handleUserNotificationFailureAction = (error) => ({
    type: ActionTypes.HANDLE_USER_NOTIFICATION_FAILURE,
    error
});

/**
 * read an user notifications
 * @param {String} notificationId
 * @param {String} email
 * @param {("new"|"read"|"delete")} actionType
 * @return {function}
 */
function handleUserNotificationAction(notificationId, email, actionType) {
    return function (dispatch, getState) {
        dispatch(handleUserNotificationRequestAction());
        const organizationId = selectCurrentOrganizationId(getState());

        updateUserNotificationAPI(notificationId, email, actionType, organizationId)
            .then(({data}) => {
                dispatch(handleUserNotificationSuccessAction(data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "update read notification error"}));
                } else {
                    dispatch(handleUserNotificationFailureAction(error.message));
                }
            });
    };
}

export {displayUserNotificationAction, handleUserNotificationAction};
