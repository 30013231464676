/**
 * @fileoverview redux actions for notifications
 */

import {authUserFailureAction} from "../../redux/actions/index";
import ActionTypes from "./notifications_action_types";
import {fetchTicker, fetchUserNotifications} from "./notifications_api";

const loadTickerRequestAction = () => ({
    type: ActionTypes.LOAD_TICKER_REQUEST
});

const loadTickerSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_TICKER_SUCCESS,
    payload
});

const loadTickerFailureAction = (error) => ({
    type: ActionTypes.LOAD_TICKER_FAILURE,
    error
});

/**
 * load global notifications for a ticker
 * @param {number} organizationId
 * @return {function}
 */
function loadTickerAction(organizationId) {
    return function (dispatch) {
        dispatch(loadTickerRequestAction());

        fetchTicker(organizationId)
            .then(({data}) => {
                dispatch(loadTickerSuccessAction(data.data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch ticker error"}));
                } else {
                    dispatch(loadTickerFailureAction(error.message));
                }
            });
    };
}

const loadUserNotificationsRequestAction = () => ({
    type: ActionTypes.LOAD_USER_NOTIFICATIONS_REQUEST
});

const loadUserNotificationsSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_USER_NOTIFICATIONS_SUCCESS,
    payload
});

const loadUserNotificationsFailureAction = (error) => ({
    type: ActionTypes.LOAD_USER_NOTIFICATIONS_FAILURE,
    error
});

/**
 * load user notifications
 * @param {number} organizationId
 * @param {String} email
 * @return {function}
 */
function loadUserNotificationsAction(organizationId, email) {
    return function (dispatch) {
        dispatch(loadUserNotificationsRequestAction());

        fetchUserNotifications(organizationId, email)
            .then(({data}) => {
                dispatch(loadUserNotificationsSuccessAction(data.data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch notifications error"}));
                } else if (error.response && error.response.status === 404) {
                    dispatch(loadUserNotificationsSuccessAction([]));
                } else {
                    dispatch(loadUserNotificationsFailureAction(error.message));
                }
            });
    };
}

const handleUserNotificationsAction = (notificationId, email, actionType) => ({
    type: ActionTypes.HANDLE_USER_NOTIFICATION,
    notificationId,
    email,
    actionType
});

const displayUserNotificationsAction = (notificationId, email) => ({
    type: ActionTypes.DISPLAY_USER_NOTIFICATION,
    notificationId,
    email
});

export {handleUserNotificationsAction, displayUserNotificationsAction, loadTickerAction, loadUserNotificationsAction};
