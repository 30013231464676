/**
 * @fileoverview API for notifications
 */

import axiosClient from "../../middleware/axios.middelware";

const BASE_URL = "/orchestrator/notifications";

/**
 * fetch current notifications of a user
 * @param {number} organizationId   the organizationId
 * @param {String} email    the user's email address
 * @return {Promise}
 */
function fetchUserNotifications(organizationId, email) {
    const params = {
        organizationId
    };

    return axiosClient.get(`${BASE_URL}/recipient/${encodeURI(email)}`, {params});
}

/**
 * fetch current notifications for the live ticker
 * @param {number} organizationId   the organizationId
 * @return {Promise}
 */
function fetchTicker(organizationId) {
    const params = {
        organization: organizationId
    };

    return axiosClient.get(`${BASE_URL}/ticker`, {params});
}

export {fetchUserNotifications, fetchTicker};
