import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        overflow: "visible"
    },
    titleArea: {
        margin: "1rem 0 0.5rem 1rem",
        display: "flex",
        justifyContent: "space-between"
    },
    left: {
        display: "flex",
        alignItems: "center"
    },
    right: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        marginRight: theme.spacing("s")
    },
    text: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "14px",
        fontWeight: theme.typography.fontWeightBold
    },
    info: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.main
    },
    error: {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.light
    },
    success: {
        color: theme.palette.success.main,
        backgroundColor: theme.palette.success.light
    },
    content: {
        fontSize: "0.875rem",
        padding: "0 3rem 1.5rem"
    }
}));
