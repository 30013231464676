// @ts-check
import {Info} from "@mui/icons-material";
import {Button} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {EVENTS} from "../../../../config/event_config";
import {requestScheduleAction} from "../../../pages/op_management/op_management_actions";
import {selectStatus} from "../../../pages/op_management/op_management_selectors";
import {selectCurrentOrganizationId} from "../../../redux/app_selectors";
import {PERMISSION, useSecurity} from "../../../utils/security";
import PermissionTooltip from "../../shared/permission_tooltip/permission_tooltip";
import useStyles from "./request_plan.styles";

/**
 * render RequestPlan component
 * @return {React.ReactElement}
 */
const RequestPlan = () => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {isGranted} = useSecurity();

    const status = useSelector(selectStatus);
    const organizationId = useSelector(selectCurrentOrganizationId);

    const handleRequestPlan = () => {
        dispatch(requestScheduleAction(organizationId));
    };

    return (
        <div className={classes.requestRoot}>
            <div className={classes.infoTicker}>
                <Info className={classes.infoIcon} />
                <div className={classes.infoText}>{t("OpBacklogView.infoRequestPlan")} </div>
            </div>
            {status.transitions?.includes(EVENTS.ManualRequestSchedulerPlanUncritical) && (
                <PermissionTooltip isAllowed={isGranted(PERMISSION.MODIFY_PLAN)}>
                    <Button className={classes.button} variant="contained" onClick={handleRequestPlan}>
                        {t("OpBacklogView.requestPlan")}
                    </Button>
                </PermissionTooltip>
            )}
        </div>
    );
};
export default RequestPlan;
