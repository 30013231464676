import PropTypes from "prop-types";
import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";

import useStyles from "./user_notifications_overview.styles";
import UserNotificationsTable from "./user_notifications_table";

const UserNotificationsOverview = ({notifications, timezone, onNotificationDelete, onNotificationConfirm, onNotificationUnread}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();

    const [page, setPage] = useState(0);

    // Set table padding: top + bottom
    const tableTopBottomPadding = 236; // in pixel

    return (
        <div>
            <div className={classes.title}>{t("Notifications.title")}</div>
            {!notifications.length ? (
                <div className={classes.empty}>{t("Notifications.empty")}</div>
            ) : (
                <div className={classes.content}>
                    <div className={classes.subtitle}>
                        <span className={classes.all}>
                            <Trans components={{bold: <strong />}} i18nKey="Notifications.all" values={{count: notifications.length}} />
                        </span>
                    </div>
                    <UserNotificationsTable
                        notifications={notifications}
                        page={page}
                        tableTopBottomPadding={tableTopBottomPadding}
                        onNotificationConfirm={onNotificationConfirm}
                        onNotificationDelete={onNotificationDelete}
                        onNotificationUnread={onNotificationUnread}
                        onSetPage={setPage}
                    />
                </div>
            )}
        </div>
    );
};

UserNotificationsOverview.propTypes = {
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            event: PropTypes.string.isRequired,
            references: PropTypes.arrayOf(PropTypes.shape({})),
            severity: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired
        })
    ).isRequired,
    timezone: PropTypes.string.isRequired,
    onNotificationDelete: PropTypes.func.isRequired,
    onNotificationConfirm: PropTypes.func.isRequired,
    onNotificationUnread: PropTypes.func.isRequired
};

export default UserNotificationsOverview;
