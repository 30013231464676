import {REFERENCE_TYPE, SEVERITY} from "../notification_constants";

const getPatientIdsFromNotification = (notification) =>
    notification.references
        ? notification.references.filter(({type}) => type === REFERENCE_TYPE.PATIENT).map(({reference}) => reference)
        : [];

/**
 * compare operator for notifications, e.g. for sorting
 * @param {Object} a
 * @param {Object} b
 * @return {number}
 */
const compareNotification = (a, b) => {
    if (a.createdAt < b.createdAt) {
        return 1;
    }

    if (a.createdAt > b.createdAt) {
        return -1;
    }

    if (a.severity === SEVERITY.HIGH && b.severity !== SEVERITY.HIGH) {
        return -1;
    }

    if (a.severity !== SEVERITY.HIGH && b.severity === SEVERITY.HIGH) {
        return 1;
    }

    return 0;
};

/**
 * group notifications by event (group only notifications with events in a positive list
 *
 * @param {Array} notifications sorted list of notifications
 * @param {Array} events        the event names to group, do not group all other events
 * @return {*}
 */
const groupNotificationsByEvent = (notifications, events) =>
    notifications.reduce((accumulator, current) => {
        if (events && !events.includes(current.event)) {
            // do not group
            accumulator[current._id] = [current];
        } else if (typeof accumulator[current.event] === "undefined") {
            accumulator[current.event] = [current];
        } else {
            accumulator[current.event] = [...accumulator[current.event], current];
        }

        return accumulator;
    }, {});

export {compareNotification, getPatientIdsFromNotification, groupNotificationsByEvent};
